import React from 'react';
import Navbar from '../../../components/trivia/NavbarOld';
import Testimonials from '../../../components/trivia/TestimonialsOld';
import Client from '../../../components/springworks/OurClients';
import Feature from '../../../components/trivia/Feature';
import Footer from '../../../components/trivia/Footer';
import '../slack/styles.scss';
import { Helmet } from 'react-helmet';
import Workspace from '../../../components/trivia/WorkSpace';

const teamsAddUrl = process.env.GATSBY_TRIVIA_MS_TEAMS_URL;

class TEAMS extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Instant Quizzes & Puzzles inside Microsoft Teams | Trivia</title>
                    <meta
                        name='description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Microsoft Teams.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Instant Quizzes & Puzzles inside Microsoft Teams | Trivia' />
                    <meta
                        property='og:description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Microsoft Teams.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/trivia/microsoft-teams/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://assets-springworks.s3.amazonaws.com/Trivia-MicrosoftTeams.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://assets-springworks.s3.amazonaws.com/Trivia-MicrosoftTeams.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta
                        property='twitter:title'
                        content='Instant Quizzes & Puzzles inside Microsoft Teams | Trivia'
                    />
                    <meta
                        property='twitter:description'
                        content='With Trivia, bring Social & Fun to your company and empower your remote team with fun quizzes, puzzles, polls & games right inside Microsoft Teams.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://assets-springworks.s3.amazonaws.com/Trivia-MicrosoftTeams.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/trivia/microsoft-teams/' />
                </Helmet>
                <div className='pageWrap'>
                    <Navbar />
                    <div className='slackHeader msTeamsHeader'>
                        <h1>Empower true team bonding inside MS Teams</h1>
                        <p>
                            Be it instant quizzes, simple polls, Gotcha! - Trivia's suite of games brings fun to your
                            remote work, right inside MS Teams.
                        </p>
                        <button onClick={() => window.open(teamsAddUrl, '_blank')} id='TRIVIA-TEAMS-INSTALL'>
                            <img
                                src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                alt={'microsoft teams'}
                            />
                            Add Trivia to Teams
                        </button>
                    </div>
                    <div className='SlackTestimonials'>
                        <h5>Thousands of organizations trust Trivia for stronger team bonding</h5>
                        <Testimonials />
                    </div>
                    <div className='SlackClient'>
                        <h6>Trusted by the world’s fastest growing companies including</h6>
                        <Client />
                    </div>
                    <div className='SlackFeature'>
                        <Feature source='MS_TEAMS' />
                    </div>

                    <div className='gChatWrapper MSTeamWrapper'>
                        <div className='sw-container'>
                            <div className='gChatAddTrivia'>
                                <div className='gChatAddTriviaHeader'>
                                    <h5>3 Simple Steps to Pump-up Team Morale and Enhance Employee Bonding</h5>
                                </div>
                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Add Trivia to MS Teams</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/teams/trivia-teams.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/trivia-teams.png'}
                                            alt={'add trivia'}
                                        />
                                    </div>

                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Add Trivia to MS Teams</h5>
                                            <p>Search for the Trivia app and install it on Teams for free.</p>
                                            <button
                                                onClick={() => window.open(teamsAddUrl, '_blank')}
                                                id='TRIVIA-TEAMS-INSTALL'
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    alt={'microsoft teams'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>

                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/teams/trivia-teams.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/trivia-teams.png'}
                                            alt={'add trivia'}
                                        />
                                    </div>
                                </div>

                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Play Trivia Games</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/teams/MSteams-games.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/MSteams-games.png'}
                                            alt={'play games'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/teams/MSteams-games.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/MSteams-games.png'}
                                            alt={'play games'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Play Trivia Games</h5>
                                            <p>
                                                Get started with Trivia's suite of games in seconds, have a laugh and
                                                bond with your teammates.
                                            </p>
                                            <button
                                                onClick={() => window.open(teamsAddUrl, '_blank')}
                                                id='TRIVIA-TEAMS-INSTALL'
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    alt={'microsoft teams'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className='gChatSteps'>
                                    <div className='gChatStepsInnerContent gMobileHeader'>
                                        <h5>Bring your team together</h5>
                                        <img
                                            src={'https://sw-assets.gumlet.io/trivia/teams/team-togather.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/team-togather.png'}
                                            alt={'come together'}
                                        />
                                    </div>
                                    <div className='gChatStepsInner gChatStepsPad'>
                                        <div className='gChatStepsInnerContent'>
                                            <h5>Bring your team together</h5>
                                            <p>
                                                With thousands of Trivia Quizzes, bring Social & Fun back to your
                                                workspace even while working remotely.
                                            </p>
                                            <button
                                                onClick={() => window.open(teamsAddUrl, '_blank')}
                                                id='TRIVIA-TEAMS-INSTALL'
                                            >
                                                <img
                                                    src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    data-src={'https://sw-assets.gumlet.io/common/ms-teams.svg'}
                                                    alt={'microsoft teams'}
                                                />
                                                Add Trivia
                                            </button>
                                        </div>
                                    </div>
                                    <div className='gChatStepsInner'>
                                        <img
                                            className='deskViewImg'
                                            src={'https://sw-assets.gumlet.io/trivia/teams/team-togather.png?blur=30'}
                                            data-src={'https://sw-assets.gumlet.io/trivia/teams/team-togather.png'}
                                            alt={'come together'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Workspace />
                    <Footer />
                </div>
            </>
        );
    }
}
export default TEAMS;
